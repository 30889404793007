<template>

  <Header />

  <main>
    <div class="container">

      <div class="upper-section">
        <h2>Contracts</h2>
        <button
          class="small"
          :class="{ 'disabled': isAPIReadOnly }"
          @click="openCreateUserContractModal()"
        >
          Add contract
        </button>
      </div>

      <Tabs>
        <template #tabs>
          <router-link
            class="tab"
            to="/contracts/overview"
          >
            Overview
          </router-link>
          <router-link
            class="tab"
            to="/contracts/history"
          >
            History
          </router-link>
        </template>
      </Tabs>

      <router-view />

    </div>
  </main>

  <Footer />

</template>

<script>

  import { mapState } from 'vuex'

  import Tabs from '@/components/page/Tabs.vue'
  import Header from '@/components/page/Header.vue'
  import Footer from '@/components/page/Footer.vue'

  export default {
    components: {
      Tabs,
      Header,
      Footer,
    },
    computed: {
      ...mapState('app', ['isAPIReadOnly']),
    },
    methods: {
      openCreateUserContractModal() {
        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'CreateUserContractModal',
          props: {
            onSuccess: (newUserContract) => {
              if (this.$route.name !== 'ContractOverview') return
              this.$store.dispatch('pagination/RESET_PAGINATION', { listName: 'userContracts' })
            },
          },
        })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  main
    @apply bg-white

    min-height: calc(100% - 128px)

  .container
    @apply p-4
    @apply pb-16
    @apply mx-auto

  .upper-section
    @apply mt-24
    @apply mb-6

    @apply flex
    @apply items-end

    h2
      @apply mb-0
      @apply mr-8

    a
      @apply flex-shrink-0

</style>
